export const UnityCallbacks = {
  OnUpdateHP: 'OnUpdateHP',
  OnFloatingText: 'OnFloatingText',
  OnSceneLoaded: 'OnSceneLoaded',
  OnBattleInited: 'OnBattleInited',
  OnUpdateRound: 'OnUpdateRound',
  OnNewAttackStarted: 'OnNewAttackStarted',
  OnAttackEnded: 'OnAttackEnded',
  OnGameOver: 'OnGameOver',
  OnNewTap: 'OnNewTap',
};

export const ReactActions = {
  LoadArmor: 'LoadArmor',
  LoadWeapon: 'LoadWeapon',
  StartBattle: 'StartBattle',
  SetBattleData: 'SetBattleData',
  NewGradePurchased: 'NewGradePurchased',
  SetTimeScale: 'SetTimeScale',
  ContinueBattle: 'ContinueBattle',
  RestartBattle: 'RestartBattle',
  SetAdressablesRemoteURL: 'SetAdressablesRemoteURL',
};
