import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import type { ThemeConfig } from 'antd';
import { ConfigProvider } from 'antd';
import { Provider } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootStore } from '../../stores/RootStore';
// import { ComputerOverlay } from '../ComputerOverlay';
import { RouterWrapper } from '../RouterWrapper';

export function App() {
  const webApp = window?.Telegram?.WebApp
  
  webApp?.enableClosingConfirmation();
  webApp?.disableVerticalSwipes();

  console.log('App, webApp=', webApp);
  console.log('platform=', webApp?.platform);

  const rootStore = new RootStore({ webAppInitData: webApp?.initData });

  let themeConfig: ThemeConfig = {};

  themeConfig.token = {
    colorBgBase: '#ffffff',
  };

  return (
    <Provider { ...rootStore }>
        <WebAppProvider>
          <ConfigProvider theme={ themeConfig }>
            <ToastContainer autoClose={ 2000 } hideProgressBar theme='dark' closeOnClick/>
            <RouterWrapper/>
          </ConfigProvider>
        </WebAppProvider>
    </Provider>
  );
}
