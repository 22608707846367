import type { ReferralUser } from '../../../models/user';
import './ReferralItem.css';
import { coinImages, PriceEnum } from '../../../types/shop';

interface IProps {
  user: ReferralUser;
  reward: number;
}

export const ReferralItem = (props: IProps) => {
  const { user, reward } = props;

  return (
    <div className='referral-user-item'>
      <div style={{ gap: '16px' }} className='referral-part'>
        <img src='/assets/ui_avatar_blank.png' alt='user-icon' className='referral-user-icon' />
        <span className='referral-username'>{`${user?.firstName || ''} ${user?.lastName || ''}`}</span>
      </div>
      <div style={{ gap: '4px' }} className='referral-part'>
        <img src={coinImages[PriceEnum.SOFT]} alt='coin-icon' className='referral-item-coin-icon' />
        <span className='referral-item-coin-text'>{`+${reward}`}</span>
      </div>
    </div>
  );
};
