import { ProjectRoutes } from '../const/ProjectRoutes';
import type { PriceEnum } from './shop';

export type DailyQuest = {
  id: number;
  dailyQuest: {
    coinType: PriceEnum;
    condition: string;
    description: string;
    questObject: QuestTypeEnum;
    amount: number;
    reward: number;
  };
  progress: number;
  completed: boolean;
  received: boolean;
};

export enum QuestTypeEnum {
  ENEMY_DEFEAT = 'ENEMY_DEFEAT',
  BATTLE_REWARDS = 'BATTLE_REWARDS',
  MERGE_ARMOR = 'MERGE_ARMOR',
  MERGE_WEAPON = 'MERGE_WEAPON',
  SHOP = 'SHOP',
}

export const QuestRoute: Record<string, string> = {
  [QuestTypeEnum.BATTLE_REWARDS]: ProjectRoutes.Combat,
  [QuestTypeEnum.ENEMY_DEFEAT]: ProjectRoutes.Combat,
  [QuestTypeEnum.MERGE_ARMOR]: ProjectRoutes.Upgrade + '?type=armor',
  [QuestTypeEnum.MERGE_WEAPON]: ProjectRoutes.Upgrade + '?type=weapon',
  [QuestTypeEnum.SHOP]: ProjectRoutes.Shop,
};

export type DailyLoginItem = {
  id: number;
  day: number;
  reward: number;
  subReward: number;
  coinType: PriceEnum;
};

export enum TaskEnum {
  TG_LINK = 'TG_LINK',
  LINK = 'LINK',
}

export type DailyReward = {
  dailyBonusClaimed: boolean;
  dailyLoginDay: number;
  coinType: PriceEnum;
  reward: number;
};

export type TaskItem = {
  id: string;
  task: {
    name: string;
    description: string;
    taskType: TaskEnum;
    tgId: number;
    link: string;
    reward: number;
    coinType: PriceEnum;
  };
  completed: boolean;
  received: boolean;
  started: boolean;
};
