import { Typography } from 'antd';
import './SkillItem.css';

interface ISkillItem {
  title: string;
  img: string;
  grade: number;
  price: number;
  money?: number;
  maxGrade: number;
  onClick: () => void;
}

export const SkillItem = (props: ISkillItem) => {
  const { img, title, grade, price, onClick, money, maxGrade } = props;
  const haveMoney = Boolean(money && money >= price);

  const getSkillItemsCount = () => {
    if (maxGrade === 10) {
      return grade;
    }

    if (grade === 0) {
      return 0;
    }

    const lastDigit = grade % 10;

    if (lastDigit === 0) {
      return 10;
    } else {
      return lastDigit;
    }
  };

  const getGradeLvl = () => {
    return Math.floor(grade / 10).toString();
  };

  return (
    <div className='skill-item'>
      <img src={img} alt='skill-img' />
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '9px' }}>
        <Typography.Text
          style={{
            color: '#19C8FA',
            fontFamily: 'Barlow-Bold',
            fontSize: '14px',
            textShadow: '1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black',
          }}
        >
          {title}
        </Typography.Text>
        <div
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            padding: maxGrade === 10 ? 0 : '2px 4px',
            backgroundImage: maxGrade === 10 ? 'none' : `url(./assets/ui-lvl_background_hp.svg)`,
            display: 'flex',
            alignItems: 'center',
            gap: '2px',
          }}
        >
          {maxGrade !== 10 && (
            <div
              style={{
                height: '14px',
                width: '25px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundImage: `url(./assets/ui-lvl_background.svg)`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <span style={{ fontSize: '13px', fontFamily: 'Barlow-Bold', color: '#000000' }}>
                {getGradeLvl()}
              </span>
            </div>
          )}
          <div className='skill-progress-background'>
            {Array(getSkillItemsCount())
              .fill(0)
              .map((x, i) => {
                return <div key={i} className='skill-progress-item' />;
              })}
          </div>
        </div>

        <div style={{ width: '100%', display: 'flex', gap: '16px', marginTop: '6px' }}>
          <div className='skill-grade'>
            <Typography.Text
              style={{
                color: '#FFFFFF',
                fontFamily: 'Barlow-Medium',
                textShadow: '1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black',
                fontSize: '12px',
              }}
            >
              {maxGrade === 10 ? grade : getSkillItemsCount()}
            </Typography.Text>
            {grade !== maxGrade && (
              <>
                <div className='skill-arrows' />
                <Typography.Text
                  style={{
                    color: '#19C8FA',
                    fontFamily: 'Barlow-Medium',
                    fontSize: '12px',
                    textShadow: '1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black',
                  }}
                >
                  {maxGrade === 10 ? grade + 1 : getSkillItemsCount() + 1}
                </Typography.Text>
              </>
            )}
          </div>
          {grade !== maxGrade && (
            <button
              style={{ opacity: grade === maxGrade || !haveMoney ? 0.5 : 1 }}
              disabled={grade === maxGrade || !haveMoney}
              onClick={onClick}
              className='buy-skill-button'
            >
              <div style={{ marginTop: '-2px' }} className='coin-icon-button' />
              <Typography.Text style={{ marginTop: '-2px' }}>{price}</Typography.Text>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
