import { Timer } from '../../../Timer';
import './SubscriptionItem.css';

interface IProps {
  icon: string;
  timer?: Date;
  amount?: number;
}

export const SubscriptionItem = (props: IProps) => {
  const { timer, icon, amount } = props;

  return (
    <div className='subscription-item'>
      <img src={icon} alt='sub-icon' />
      {timer !== undefined && <Timer date={timer} forFight onExpire={() => {}} />}
      {amount !== 0 && amount !== undefined && <span className='title'>{amount}</span>}
    </div>
  );
};
