import './Layout.css';
import type { PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router';
import { ProjectRoutes } from '../../../const/ProjectRoutes';
import { useStores } from '../../../hooks/useStore';
import { Footer } from '../Footer';

export const Layout = observer((props: PropsWithChildren) => {
  const { gameS } = useStores();
  const { pathname } = useLocation();

  return (
    <>
      <div className='main-screen'>
        {props.children}
        {gameS?.gameInfo?.tutorialPassed && pathname !== ProjectRoutes.Combat && (
          <div className='ui-overlay__footer'>
            <Footer />
          </div>
        )}
      </div>
      <div className='landscape-overlay'>
        <img src='./assets/ui_img_playmobile.png' alt='overlay-icon' />
        <span>Please flip the smartphone vertically</span>
      </div>
    </>
  );
});
