import './Task.css';
import React, { useState } from 'react';
import { Typography } from 'antd';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { useStores } from '../../../hooks/useStore';
import type { TaskItem } from '../../../types/quests';
import { TaskEnum } from '../../../types/quests';
import { coinImages, PriceEnum } from '../../../types/shop';
import { Timer } from '../../Timer';

interface IProps {
  task: TaskItem;
  openUrl: (url: string) => void;
  openTgUrl: (url: string) => void;
  playCoinReward?: number;
}

export const Task = observer((props: IProps) => {
  const { task, openUrl, openTgUrl, playCoinReward } = props;
  const { dailieS } = useStores();
  const [dateTimer, setDateTimer] = useState<Date | undefined>(undefined);

  const onTaskClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (task.started) {
      dailieS.getTaskReward(task.id, Boolean(playCoinReward)).then((result) => {
        if (result && !result.completed) {
          toast.success('Task not confirmed', {
            closeButton: false,
            icon: false,
            progress: undefined,
          });
          const newDate = new Date();
          newDate.setSeconds(newDate.getSeconds() + 60);
          setDateTimer(newDate);
        }
      });
    } else {
      dailieS.startTask(task.id).then((result) => {
        if (result) {
          if (task.task.taskType === TaskEnum.LINK) {
            openUrl(task.task.link);
          } else {
            openTgUrl(task.task.link);
          }
        }
      });
    }
  };

  const onTaskItemClick = () => {
    if (!task.completed) {
      if (task.task.taskType === TaskEnum.LINK) {
        openUrl(task.task.link);
      } else {
        openTgUrl(task.task.link);
      }
    }
  };

  return (
    <div className='task-item' onClick={onTaskItemClick}>
      <div className='task-container'>
        <img src='/assets/ui_telegram-icon.png' alt='link-icon' />
        <div className='task-description-container'>
          <span className='task-title'>{task.task.name}</span>
          <span className='task-description'>{task.task.description}</span>
          <div className='task-rewards'>
            {Boolean(playCoinReward) && (
              <span className='task-reward'>
                <img alt='coin-icon' src={coinImages[PriceEnum.PLAYCOIN]} />
                {`+${playCoinReward}`}
              </span>
            )}
            <span className='task-reward'>
              <img alt='coin-icon' src={coinImages[task.task.coinType]} />
              {`+${task.task.reward}`}
            </span>
          </div>
        </div>
      </div>
      {task.completed ? (
        <img style={{ width: '24px' }} src={'/assets/ui_button_check.png'} alt='check-icon' />
      ) : (
        <button
          disabled={!!dateTimer}
          className='get-task-reward-button'
          style={{
            backgroundImage: task.started
              ? `url(./assets/ui_button_sm_act.png)`
              : `url(./assets/ui_button_sm.png)`,
            opacity: !dateTimer ? 1 : 0.5,
          }}
          onClick={(event) => onTaskClick(event)}
        >
          {dateTimer ? (
            <Timer date={dateTimer} onExpire={() => setDateTimer(undefined)} />
          ) : (
            <Typography.Text className='get-reward-text'>
              {task.started ? 'CHECK' : 'GO!'}
            </Typography.Text>
          )}
        </button>
      )}
    </div>
  );
});
