export type TLeague = {
  id: number;
  name: string;
  max_battlescore: number;
};

export type TLeaguePlace = {
  leaguePlace: number;
  battlescore: number;
  kills: number;
  lastName: string;
  firstName: string;
};

export type TLeaguePlaces = TLeaguePlace[];

export const leagueImages: Record<number, string> = {
  1: '/assets/league/ui_trophy_bronze.svg',
  2: '/assets/league/ui_trophy_silver.svg',
  3: '/assets/league/ui_trophy_gold.svg',
  4: '/assets/league/ui_trophy_platinum.svg',
  5: '/assets/league/ui_trophy_diamond.svg',
  6: '/assets/league/ui_trophy_master.svg',
  7: '/assets/league/ui_trophy_royal.svg',
  8: '/assets/league/ui_trophy_godlike.svg',
};

export const leagueBackground: Record<number, string> = {
  1: 'radial-gradient(106.78% 76% at 50.13% 24%, #83532A 0%, #14140C 100%)',
  2: 'radial-gradient(106.78% 76% at 50.13% 24%, #838383 0%, #141414 100%)',
  3: 'radial-gradient(106.78% 76% at 50.13% 24%, #BF9F00 0%, #593C2D 100%)',
  4: 'radial-gradient(106.78% 76% at 50.13% 24%, #B2B2B2 0%, #333 100%)',
  5: 'radial-gradient(106.78% 76% at 50.13% 24%, #DAE2E5 0%, #17191A 100%)',
  6: 'radial-gradient(106.78% 76% at 50.13% 24%, #2B2B2B 0%, #B24747 100%)',
  7: 'radial-gradient(106.78% 76% at 50.13% 24%, #FFA64D 0%, #661F1F 100%)',
  8: 'radial-gradient(106.78% 76% at 50.13% 24%, #C34DFF 0%, #271A80 100%)',
};

export const leagueIcons: Record<number, string> = {
  1: '/assets/league/icons/BronzeIcons.svg',
  2: '/assets/league/icons/SilverIcons.svg',
  3: '/assets/league/icons/GoldenIcons.svg',
  4: '/assets/league/icons/PlatinumIcons.svg',
  5: '/assets/league/icons/DiaomondIcons.svg',
  6: '/assets/league/icons/MasterIcons.svg',
  7: '/assets/league/icons/RoyalIcons.svg',
  8: '/assets/league/icons/GodlikeIcons.svg',
};

export const leagueTitleColor: Record<number, string> = {
  1: '#FDC266',
  2: '#E6E6E6',
  3: '#FFEA80',
  4: '#FAFAFA',
  5: '#E5F6FF',
  6: '#FF3333',
  7: '#FFA64D',
  8: '#FF80FF',
};
