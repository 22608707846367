import type { AxiosInstance } from 'axios';
import { makeAutoObservable } from 'mobx';
import type { GameInfo } from '../models/game';
import type { DailyLoginItem, DailyQuest, DailyReward, TaskItem } from '../types/quests';
import { PriceEnum } from '../types/shop';
import type { GameStore } from './GameStore';
import { RequestStore } from './RequestStore';

type Props = {
  axiosInstance: AxiosInstance;
  gameS: GameStore;
};

export class DailyStore {
  request;
  private readonly client: AxiosInstance;
  gameS: GameStore;

  public constructor(props: Props) {
    this.client = props.axiosInstance;
    this.gameS = props.gameS;
    this.request = {
      getLoginBonusList: new RequestStore<DailyLoginItem[]>({
        client: this.client,
        url: '/daily_login_bonus_list',
        method: 'get',
      }),
      getLoginBonus: new RequestStore<DailyReward>({
        client: this.client,
        url: '/daily_login_bonus',
        method: 'get',
      }),
      getDailyQuests: new RequestStore<DailyQuest[]>({
        client: this.client,
        url: '/daily_quests',
        method: 'get',
      }),
      getDailyQuestReward: new RequestStore<GameInfo>({
        client: this.client,
        url: '/daily_quest_reward/${id}',
        method: 'get',
      }),
      getTaskList: new RequestStore<TaskItem[]>({
        client: this.client,
        url: '/tasks',
        method: 'get',
      }),
      startTask: new RequestStore<TaskItem>({
        client: this.client,
        url: '/start_task/${id}',
        method: 'get',
      }),
      getTaskReward: new RequestStore<TaskItem>({
        client: this.client,
        url: '/task_reward/${id}',
        method: 'get',
      }),
    };
    makeAutoObservable(this);
  }

  private _loginBonus: DailyLoginItem[] = [];
  private _dailyQuests: DailyQuest[] = [];
  private _taskList: TaskItem[] = [];

  get taskList() {
    return this._taskList;
  }

  private setTaskList(value: typeof this._taskList) {
    this._taskList = value;
  }

  get loginBonus() {
    return this._loginBonus;
  }

  private setLoginBonus(value: typeof this._loginBonus) {
    this._loginBonus = value;
  }

  get dailyQuests() {
    return this._dailyQuests;
  }

  private setDailyQuests(value: typeof this._dailyQuests) {
    this._dailyQuests = value;
  }

  public async getLoginBonusList() {
    return await this.request.getLoginBonusList.request().then(() => {
      const { data } = this.request.getLoginBonusList.result;

      if (data) {
        this.setLoginBonus(data);
        return true;
      }
      return false;
    });
  }

  public async getLoginBonusReward() {
    return await this.request.getLoginBonus.request().then(() => {
      const { data } = this.request.getLoginBonus.result;

      if (data) {
        return data;
      }
      return undefined;
    });
  }

  public async getDailyQuestsList() {
    return await this.request.getDailyQuests.request().then(() => {
      const { data } = this.request.getDailyQuests.result;

      if (data) {
        this.setDailyQuests(data);
        return true;
      }
      return false;
    });
  }

  public async getDailyQuestReward(id: number) {
    return await this.request.getDailyQuestReward.request({ urlProps: { id } }).then(() => {
      const { data } = this.request.getDailyQuestReward.result;

      if (data) {
        const finishedQuest = this._dailyQuests.find((x) => x.id === id);
        this.setDailyQuests(this._dailyQuests.filter((x) => x.id !== id));
        if (finishedQuest?.dailyQuest?.coinType === PriceEnum.PLAYCOIN) {
          this.gameS.fetchPlayDeckProfile();
        }
        this.gameS.setGameInfo(data);
        return true;
      }
      return false;
    });
  }

  public async getTasksList() {
    return await this.request.getTaskList.request().then(() => {
      const { data } = this.request.getTaskList.result;

      if (data) {
        this.setTaskList(data);
        return true;
      }
      return false;
    });
  }

  public async getTaskReward(id: string, needPDCUpdate: boolean = false) {
    return await this.request.getTaskReward.request({ urlProps: { id } }).then(() => {
      const { data } = this.request.getTaskReward.result;

      if (data) {
        this.setTaskList(
          this._taskList.map((item) => {
            if (item.id === id) {
              return data;
            }
            return item;
          }),
        );

        if (data.completed) {
          this.gameS.setGameInfo({
            ...this.gameS.gameInfo,
            softCoins: this.gameS.gameInfo.softCoins + data.task.reward,
          });
          if (needPDCUpdate) {
            this.gameS.fetchPlayDeckProfile();
          }
        }
        return data;
      }
      return data;
    });
  }

  public async startTask(id: string) {
    return await this.request.startTask.request({ urlProps: { id } }).then(() => {
      const { data } = this.request.startTask.result;

      if (data) {
        this.setTaskList(
          this._taskList.map((item) => {
            if (item.id === id) {
              return data;
            }
            return item;
          }),
        );
        return data;
      }

      return data;
    });
  }
}
