import {ReactActions} from "./actions";

export enum ModelTypeEnum {
  ARMOR = 'armor',
  WEAPON = 'weapon'
}

export const ModelTypesActions = {
  [ModelTypeEnum.ARMOR]: ReactActions.LoadArmor,
  [ModelTypeEnum.WEAPON]: ReactActions.LoadWeapon,
}
