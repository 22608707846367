import './Tabs.css';

interface IProps {
  tabs: string[];
  activeTab: string;
  updateActiveTab: (active: string) => void;
  imageName: string;
}

export const Tabs = (props: IProps) => {
  const { tabs, activeTab, updateActiveTab, imageName } = props;

  return (
    <div className='upgrade-footer'>
      <div className='gradient' />
      <div style={{ position: 'relative' }}>
        <img
          className='tabs'
          src={`/assets/${imageName}${tabs.indexOf(activeTab) + 1}.png`}
          alt='tabs'
        />
        <div
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex' }}
        >
          {tabs.map((tab) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <button
                key={tab}
                className='tab-area'
                onClick={() => {
                  updateActiveTab(tab);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
