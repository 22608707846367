import { Flex } from 'antd';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout } from '../../components/main/Layout';
import { AutoFightModal } from '../../components/Modals';
import { UnityContainer } from '../../components/UnityContainer';
import { ProjectRoutes } from '../../const/ProjectRoutes';
import { useStores } from '../../hooks/useStore';

const RootPage = observer(() => {
  const { gameS } = useStores();
  const { pathname } = useLocation();

  const onClaimRewardClick = () => {
    gameS?.claimAutoFightReward();
  };

  return (
    <Layout>
      <UnityContainer />
      {pathname !== ProjectRoutes.Combat && (
        <Flex
          vertical
          style={{ width: '100vw', height: '100vh', position: 'relative', zIndex: 2 }}
          justify={'center'}
        >
          <Outlet />
        </Flex>
      )}
      {gameS?.gameInfo?.delayedReward ? (
        <AutoFightModal
          reward={gameS?.gameInfo?.delayedReward}
          onClaimRewardClick={onClaimRewardClick}
        />
      ) : undefined}
    </Layout>
  );
});

export default RootPage;
