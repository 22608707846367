import { observer } from 'mobx-react';

const UpgradePage = observer(() => {

  return (
   <>
   </>
  );
});

export default UpgradePage;
