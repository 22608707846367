import './LeagueUi.css';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/useStore';
import type { TLeague } from '../../types/league';
import { leagueBackground, leagueImages, leagueTitleColor } from '../../types/league';
import { UserLeaguePlaceItem } from '../League/UserLeaguePlaceItem';
import { Loader } from '../main/Loader';

export const LeagueUi = observer(() => {
  const { leagueS, gameS } = useStores();
  const [currentLeague, setCurrentLeague] = useState<TLeague | undefined>(undefined);

  useEffect(() => {
    if (
      !currentLeague &&
      leagueS?.leagueDictionary?.length === 0 &&
      !leagueS?.request?.getLeagueDictionary?.loader
    ) {
      leagueS.getLeagueDictionary();
    }
  }, []);

  useEffect(() => {
    setCurrentLeague(leagueS?.leagueDictionary?.find((x) => x.id === gameS?.gameInfo?.leagueId));
  }, [leagueS?.leagueDictionary, gameS?.gameInfo]);

  useEffect(() => {
    if (currentLeague && !leagueS?.request?.getLeaguePlaces?.loader) {
      leagueS.getLeaguePlaces(currentLeague?.id);
    }
  }, [currentLeague]);

  const onChangeLeague = (indexChange: number) => {
    if (currentLeague) {
      const index = leagueS?.leagueDictionary?.indexOf(currentLeague);
      setCurrentLeague(leagueS?.leagueDictionary[index + indexChange]);
    }
  };

  return currentLeague ? (
    <div
      className='league-ui-layout'
      style={{ background: leagueBackground[currentLeague?.id || 1] }}
    >
      <div className='league-header'>
        <button
          className='arrow-button'
          disabled={currentLeague?.id === leagueS?.leagueDictionary[0]?.id}
          style={{
            transform: 'rotate(180deg)',
            opacity: currentLeague?.id === leagueS?.leagueDictionary[0]?.id ? 0.5 : 1,
          }}
          onClick={() => onChangeLeague(-1)}
        />
        <div className='league-info'>
          <img src={leagueImages[currentLeague?.id || 1]} alt={`${currentLeague?.name}_icon`} />
          <span className='league-title'>{`${currentLeague?.name} League`}</span>
          <span
            className='league-description'
            style={{ color: leagueTitleColor[currentLeague?.id || 1] }}
          >{`BS: ${leagueS?.leagueDictionary?.find((x) => x.id === currentLeague?.id - 1)?.max_battlescore || 0}+`}</span>
        </div>
        <button
          className='arrow-button'
          disabled={
            currentLeague?.id ===
            leagueS?.leagueDictionary[leagueS?.leagueDictionary?.length - 1]?.id
          }
          style={{
            opacity:
              currentLeague?.id ===
              leagueS?.leagueDictionary[leagueS?.leagueDictionary?.length - 1]?.id
                ? 0.5
                : 1,
          }}
          onClick={() => onChangeLeague(1)}
        />
      </div>
      {currentLeague?.id === gameS?.gameInfo?.leagueId && (
        <UserLeaguePlaceItem
          place={gameS?.gameInfo?.leaguePlace}
          battleScore={gameS?.gameInfo?.battlescore}
          isUser
          firstName=''
          lastName=''
          kills={gameS?.gameInfo?.kills}
        />
      )}
      <div className='league-place-list'>
        <div className='mask'>
          {leagueS?.leaguePlaces?.length > 0 ? (
            leagueS?.leaguePlaces?.map((place, index) => {
              return (
                <UserLeaguePlaceItem
                  key={index}
                  battleScore={place.battlescore}
                  firstName={place.firstName}
                  lastName={place.lastName}
                  kills={place.kills}
                  place={index + 1}
                />
              );
            })
          ) : (
            <div
              style={{
                textAlign: 'center',
                padding: '20.5px',
                background: 'linear-gradient(358deg, #0C0C0C -61.48%, rgba(0, 0, 0, 0.00) 161.47%)',
              }}
            >
              <span className='league-description' style={{ color: '#FFFFFF', fontSize: '16px' }}>
                No data
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
});
