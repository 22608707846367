export type TShopItem = {
  id: number;
  name: string;
  amount: number;
  itemType: ShopItemEnum;
  contents: string;
  price: number;
  coinType: PriceEnum;
  sub_time: number;
};

export enum ShopItemEnum {
  SOFT_COIN_PACK = 'SOFT_COIN_PACK',
  BOOST_DMG_SUB = 'BOOST_DMG_SUB',
  BOOST_DMG_MULTIPLIER_SUB = 'BOOST_DMG_MULTIPLIER_SUB',
  FREE_RESURRECTION_PACK = 'FREE_RESURRECTION_PACK',
  AUTO_FIGHT_SUB = 'AUTO_FIGHT_SUB',
  BOOST_FIGHT_REWARD_SUB = 'BOOST_FIGHT_REWARD_SUB',
  BOOST_DAILY_BONUS_SUB = 'BOOST_DAILY_BONUS_SUB',
}

export enum PriceEnum {
  SOFT = 'SOFT',
  PLAYCOIN = 'PLAYCOIN',
  STARS = 'STARS',
}

export const coinImages: Record<string, string> = {
  [PriceEnum.SOFT]: '/assets/ui_icon_currency_coin.png',
  [PriceEnum.STARS]: '/assets/ui_icon_tgstar_sm.png',
  [PriceEnum.PLAYCOIN]: '/assets/ui_icon_currency_crystal.png',
};
