import './SubPackItem.css';
import type { TShopItem } from '../../../types/shop';
import { coinImages, ShopItemEnum } from '../../../types/shop';
import { Timer } from '../../Timer';

interface IProps {
  item: TShopItem;
  hasResurrection?: number;
  hasSub?: Date;
  canBuy: boolean;
  onClick: (item: TShopItem) => void;
  onExpire: (item: TShopItem) => void;
}

const packImages: Record<string, string> = {
  [ShopItemEnum.BOOST_DAILY_BONUS_SUB]: '/assets/ui_store_dailyrewardboost.png',
  [ShopItemEnum.AUTO_FIGHT_SUB]: '/assets/ui_store_autobattle.png',
  [ShopItemEnum.BOOST_DMG_MULTIPLIER_SUB]: '/assets/ui_store_atkpowerup.png',
  [ShopItemEnum.BOOST_FIGHT_REWARD_SUB]: '/assets/ui_store_battlerewardboost.png',
  [ShopItemEnum.BOOST_DMG_SUB]: '/assets/ui_store_tappowerup.png',
  [ShopItemEnum.FREE_RESURRECTION_PACK]: '/assets/ui_store_revive.png',
};

export const SubPackItem = (props: IProps) => {
  const { item, onClick, canBuy, hasSub, onExpire, hasResurrection } = props;

  const renderTextOnButton = () => {
    if (
      hasResurrection &&
      hasResurrection > 0 &&
      item.itemType === ShopItemEnum.FREE_RESURRECTION_PACK
    ) {
      return <span className='pack-buy-button-text'>{`${hasResurrection}/${item.amount}`}</span>;
    }

    if (hasSub) {
      return <Timer date={hasSub} onExpire={() => onExpire(item)} />;
    }

    return (
      <>
        <img src={coinImages[item.coinType]} alt='coin-icon' />
        <span className='pack-buy-button-text'>{item.price}</span>
      </>
    );
  };

  return (
    <div className='pack-item'>
      <img src={packImages[item.itemType]} alt='img' />
      <div className='pack-description-container'>
        <span className='pack-title'>{item.name}</span>
        <span className='pack-description'>{item.contents}</span>
        <button
          style={{
            backgroundImage: `url(${canBuy ? './assets/ui_button_m.png' : './assets/ui_button_m_off.png'}`,
          }}
          disabled={!canBuy}
          className='pack-buy-button'
          onClick={() => onClick(item)}
        >
          {renderTextOnButton()}
        </button>
      </div>
    </div>
  );
};
