import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import './StartLevelUi.css';

interface IProps {
  currentRound: number;
  onRoundChange: (round: number) => void;
}

export const StartLevelUi = observer((props: IProps) => {
  const { onRoundChange, currentRound } = props;
  const { gameS } = useStores();

  const getMaxRound = () => {
    const nextRound = gameS?.gameInfo?.maxRound?.toString();

    return parseFloat(nextRound?.substring(0, nextRound?.length - 1) + 0);
  };

  if (gameS?.gameInfo?.maxRound < 10) {
    return <></>;
  }

  return (
    <div className='start-level-background'>
      <span className='start-level-title'>Starting level</span>
      <div
        className='start-level-item'
        style={{
          backgroundImage:
            1 === currentRound
              ? `url("./assets/ui_button_circle_sm.png")`
              : `url("./assets/ui_button_circle_sm_off.png")`,
        }}
        onClick={() => onRoundChange(1)}
      >
        <span>1</span>
      </div>
      <div
        className='start-level-item'
        style={{
          backgroundImage:
            getMaxRound() === currentRound
              ? `url("./assets/ui_button_circle_sm.png")`
              : `url("./assets/ui_button_circle_sm_off.png")`,
        }}
        onClick={() => onRoundChange(getMaxRound())}
      >
        <span>{getMaxRound()}</span>
      </div>
    </div>
  );
});
