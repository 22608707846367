import './ScoreItem.css';
import { Typography } from 'antd';
import { numberFormatter } from '../../utils/formulas';

export type TScoreItem = {
  title: string;
  value?: number;
  battleScore?: boolean;
};

export const ScoreItem = (props: TScoreItem) => {
  const { value, title, battleScore } = props;

  if (battleScore) {
    return (
      <div className='score-item'>
        <div className='battle-score'>
          <Typography.Text className='battle-value'>{numberFormatter(value || 0)}</Typography.Text>
          <Typography.Text className='battle-title'>{title}</Typography.Text>
        </div>
      </div>
    );
  }

  return (
    <div className='score-item'>
      <img src='/assets/ui_bg_stats.png' alt='score-background' />
      <div className='score-description'>
        <Typography.Text className='score-value'>{numberFormatter(value || 0)}</Typography.Text>
        <Typography.Text className='score-title'>{title}</Typography.Text>
      </div>
    </div>
  );
};
