import './TaskList.css';
import { useEffect } from 'react';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { Task } from '../Task';

export const TaskList = observer(() => {
  const { dailieS, configS } = useStores();
  const webApp = useWebApp();

  useEffect(() => {
    dailieS?.getTasksList();
  }, []);

  const onOpenUrl = (url: string) => {
    webApp.openLink(url);
  };

  const onOpenTgUrl = (url: string) => {
    webApp.openTelegramLink(url);
  };

  return (
    <div className='task-list'>
      {dailieS?.taskList?.map((task) => {
        return (
          <Task
            task={task}
            playCoinReward={configS?.config?.PDC_TASKS}
            openTgUrl={onOpenTgUrl}
            openUrl={onOpenUrl}
            key={task.id}
          />
        );
      })}
    </div>
  );
});
