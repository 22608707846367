import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useExpand, useInitData, useWebApp } from '@vkruglikov/react-telegram-web-app';
import { observer } from 'mobx-react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ProjectRoutes } from '../../const/ProjectRoutes';
import { useStores } from '../../hooks/useStore';
import { ErrorPage, LeaguePage, QuestsPage, ReferralPage, RootPage, UpgradePage, } from '../../pages';
import PausePage from '../../pages/Pause';
import ShopPage from '../../pages/Shop';
import { ComputerOverlay } from '../ComputerOverlay';
import { ErrorOverlay } from '../ErrorOverlay';
import { Loader } from '../main/Loader';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const RouterWrapper = observer(() => {
  const WebApp = useWebApp();
  const [initDataUnsafe, initData] = useInitData();
  const [isExpanded, expand] = useExpand();

  console.log('initDataUnsafe=', initDataUnsafe);
  console.log('initData=', initData);

  const {
    tokenS: { isLoaded, isAuth, error },
    configS, gameS, leagueS,
  } = useStores();

  if (!isExpanded) {
    expand();
  }

  if (
    process.env.REACT_APP_ENVIRONMENT === 'production' &&
    WebApp?.platform &&
    WebApp?.platform !== 'android' &&
    WebApp?.platform !== 'ios' &&
    ![252719979, 254345450, 381715472].includes(WebApp?.initDataUnsafe?.user?.id)
  ) {
    return <ComputerOverlay/>;
  }

  useEffect(() => {
    if (isAuth && isLoaded) {
      configS?.fetchConfig().then();
      gameS?.fetchGameInfo().then();
      gameS?.fetchPlayDeckProfile().then();
      if (gameS?.userSubs?.length !== 0) {
        gameS.getUserSubs().then();
      }
      if (leagueS?.leagueDictionary?.length === 0 && !leagueS?.request?.getLeagueDictionary?.loader) {
        leagueS?.getLeagueDictionary();
      }
    }
  }, [isLoaded, isAuth]);

  const paths = sentryCreateBrowserRouter([
    {
      path: ProjectRoutes.Combat,
      element: <RootPage />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: ProjectRoutes.Referral,
          element: <ReferralPage />,
        },
        {
          path: ProjectRoutes.Upgrade,
          element: <UpgradePage />,
        },
        {
          path: ProjectRoutes.Quests,
          element: <QuestsPage />,
        },
        {
          path: ProjectRoutes.League,
          element: <LeaguePage />,
        },
        {
          path: ProjectRoutes.Shop,
          element: <ShopPage />,
        },
        {
          path: ProjectRoutes.Pause,
          element: <PausePage />,
        },
      ],
    },
  ]);

  console.log('isAuth=', isAuth);

  if (!isLoaded) {
    return <Loader />;
  }

  if (isLoaded && !isAuth) {
    return <ComputerOverlay/>;
  }

  if (error) {
    return <ErrorOverlay error={error} />;
  }

  return <RouterProvider router={paths} />;
});
