import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { ShopItemEnum } from '../../../types/shop';
import { SubscriptionItem } from './SubscriptionItem';
import './Subscriptions.css';

const icons: Record<string, string> = {
  [ShopItemEnum.FREE_RESURRECTION_PACK]: './assets/ui_icon_timer_revive_sm.png',
  [ShopItemEnum.AUTO_FIGHT_SUB]: './assets/ui_icon_timer_autobattle_sm.png',
  [ShopItemEnum.BOOST_DMG_SUB]: './assets/ui_icon_timer_tapboost_02_sm.png',
  [ShopItemEnum.BOOST_DMG_MULTIPLIER_SUB]: './assets/ui_icon_timer_atkboost_sm.png',
  [ShopItemEnum.BOOST_FIGHT_REWARD_SUB]: './assets/ui_icon_timer_battleearningsboost_sm.png',
  [ShopItemEnum.BOOST_DAILY_BONUS_SUB]: './assets/ui_icon_timer_dailyrewardboost_sm.png',
};

export const Subscriptions = observer(() => {
  const { gameS } = useStores();

  return (
    <div className='subscriptions-container'>
      {gameS?.gameInfo?.freeResurrectionCount > 0 && (
        <SubscriptionItem
          icon={icons[ShopItemEnum.FREE_RESURRECTION_PACK]}
          amount={gameS?.gameInfo?.freeResurrectionCount}
        />
      )}
      {gameS?.userSubs?.map((subscription) => {
        if (!icons[subscription.shopItem.itemType]) {
          return undefined;
        }
        return (
          <SubscriptionItem
            timer={subscription.activeUntil}
            icon={icons[subscription.shopItem.itemType]}
            key={subscription.id}
          />
        );
      })}
    </div>
  );
});
