import { useEffect, useState } from 'react';
import { Typography } from 'antd';
// eslint-disable-next-line import/order
import { observer } from 'mobx-react';
import './QuestsUi.css';

import { useStores } from '../../hooks/useStore';
import { PriceEnum, ShopItemEnum } from '../../types/shop';
import { Currency } from '../Currency';
import { DailyTask, TaskList } from '../Quests';
import { DailyBonus } from '../Quests/DailyBonus';
import { Tabs } from '../Tabs';

const tabs: string[] = ['daily', 'tasks', 'achievements'];

export const QuestsUi = observer(() => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const { gameS, dailieS } = useStores();

  useEffect(() => {
    dailieS?.getDailyQuestsList();
  }, []);

  useEffect(() => {
    if (dailieS?.loginBonus?.length === 0 && !dailieS?.request?.getLoginBonusList?.loader) {
      dailieS?.getLoginBonusList();
    }
  }, []);

  useEffect(() => {
    if (gameS?.userSubs?.length !== 0) {
      gameS.getUserSubs();
    }
  }, []);

  const onComplete = (id: number) => {
    dailieS?.getDailyQuestReward(id);
  };

  const onGetDailyReward = () => {
    dailieS?.getLoginBonusReward().then((res) => {
      if (res) {
        if (res.coinType === PriceEnum.PLAYCOIN) {
          gameS?.fetchPlayDeckProfile();
          gameS?.setGameInfo({
            ...gameS?.gameInfo,
            ...res,
          });
        } else {
          gameS?.setGameInfo({
            ...gameS?.gameInfo,
            ...res,
            softCoins: gameS?.gameInfo?.softCoins + res.reward,
          });
        }
      }
      gameS?.setGameInfo({
        ...gameS?.gameInfo,
        ...res,
      });
    });
  };

  const renderTab = () => {
    const hasSubscription = Boolean(
      gameS?.userSubs?.find((x) => x.shopItem.itemType === ShopItemEnum.BOOST_DAILY_BONUS_SUB),
    );

    if (activeTab === 'daily') {
      return (
        <div className='quest-dailies-container'>
          <div className='quest-daily-bonus-container'>
            <span className='quest-daily-bonus-title'>Daily Login Bonus</span>
            <span className='quest-daily-bonus-description'>
              Login in daily to claim your rewards!
            </span>
            <div className='quest-daily-bonuses'>
              {dailieS?.loginBonus
                ?.slice()
                ?.sort((a, b) => (a.day < b.day ? -1 : 0))
                ?.map((daily) => {
                  return (
                    <DailyBonus
                      key={daily.id}
                      item={daily}
                      dailyDay={gameS?.gameInfo?.dailyLoginDay}
                      dailyClaimed={gameS?.gameInfo?.dailyBonusClaimed}
                      hasSub={hasSubscription}
                    />
                  );
                })}
            </div>
            <button
              onClick={onGetDailyReward}
              className='quest-daily-claim'
              disabled={gameS?.gameInfo?.dailyBonusClaimed}
              style={{ opacity: gameS?.gameInfo?.dailyBonusClaimed ? 0.5 : 1 }}
            >
              <span>{`CLAIM ${hasSubscription ? 'X2 ' : ''}REWARDS`}</span>
            </button>
          </div>
          {dailieS?.dailyQuests
            ?.filter((x) => !x.received)
            .map((quest) => {
              return <DailyTask key={quest.id} onComplete={onComplete} {...quest} />;
            })}
        </div>
      );
    }

    if (activeTab === 'tasks') {
      return <TaskList />;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <img
          src={'/assets/ui_img_construction.png'}
          style={{ width: '80%', margin: '0 auto' }}
          alt='under-construction'
        />
        <Typography.Text style={{ color: '#FFFFFF', fontFamily: 'Barlow-Regular' }}>
          This feature is under construction
        </Typography.Text>
      </div>
    );
  };

  return (
    <div className='quests-ui-layout'>
      <div className='quests-ui-container'>
        <Currency sticky />
        {renderTab()}
      </div>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        updateActiveTab={setActiveTab}
        imageName='ui_tabs_quests_block_0'
      />
    </div>
  );
});
