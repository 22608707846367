import {observer} from "mobx-react";
import {QuestsUi} from "../../components/QuestsUi";

const QuestsPage = observer(() => {
  return (
    <QuestsUi />
  )
})

export default QuestsPage;
