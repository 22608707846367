import { Col, Row, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import './Footer.css';
import { ProjectRoutes } from '../../../const/ProjectRoutes';

interface IFooterButton {
  title: string;
  icon: string;
  iconActive: string;
  path: string;
}

interface IFooterTabProps extends IFooterButton {
  activePath: string;
  onClick?: (path: string) => void;
}

const FooterTab = (props: IFooterTabProps) => {
  const { icon, path, activePath, title, iconActive, onClick } = props;
  const navigate = useNavigate();

  const onTabClick = () => {
    if (onClick) {
      onClick(path);
    } else {
      navigate(path);
    }
  };

  return (
    <Col xs={4} className='footer-tab' onClick={onTabClick}>
      <img
        src={
          activePath === path
            ? './assets/ui_nav_bg_merged_act.png'
            : './assets/ui_nav_bg_merged.png'
        }
        className='footer-tab-image'
        alt='background'
      />
      <img src={activePath === path ? iconActive : icon} className='footer-tab-icon' alt='icon' />
      <Typography.Text
        className='footer-tab-title'
        style={{
          color: activePath === path ? '#FFFFFF' : '#19C8FA',
        }}
      >
        {title}
      </Typography.Text>
    </Col>
  );
};

interface IFooterProps {
  onTabClick?: (path: string) => void;
}

export const Footer = (props: IFooterProps) => {
  const { onTabClick } = props;
  const { pathname } = useLocation();

  const footerButtons: IFooterButton[] = [
    {
      title: 'Shop',
      icon: './assets/ui_nav_icon_shop.png',
      iconActive: './assets/ui_nav_icon_shop_act.png',
      path: ProjectRoutes.Shop,
    },
    {
      title: 'Earn',
      icon: './assets/ui_nav_icon_quests.png',
      iconActive: './assets/ui_nav_icon_quests_act.png',
      path: ProjectRoutes.Quests,
    },
    {
      title: 'Fight',
      icon: './assets/ui_nav_icon_combat.png',
      iconActive: './assets/ui_nav_icon_combat_act.png',
      path: ProjectRoutes.Combat,
    },
    {
      title: 'Upgrade',
      icon: './assets/ui_nav_icon_upgrade.png',
      iconActive: './assets/ui_nav_icon_upgrade_act.png',
      path: ProjectRoutes.Upgrade,
    },
    {
      title: 'Invite',
      icon: './assets/ui_nav_icon_invite.png',
      iconActive: './assets/ui_nav_icon_invite_act.png',
      path: ProjectRoutes.Referral,
    },
  ];

  return (
    <Row className='footer-container'>
      {footerButtons.map((button, index) => {
        return <FooterTab key={index} {...button} onClick={onTabClick} activePath={pathname} />;
      })}
    </Row>
  );
};
