import type { AxiosInstance } from 'axios';
import { makeAutoObservable } from 'mobx';
import type { TLeague, TLeaguePlaces } from '../types/league';
import { RequestStore } from './RequestStore';

type Props = {
  axiosInstance: AxiosInstance;
};

export class LeagueStore {
  request;
  private readonly client: AxiosInstance;

  public constructor(props: Props) {
    this.client = props.axiosInstance;
    this.request = {
      getLeagueDictionary: new RequestStore<TLeague[]>({
        client: this.client,
        url: '/leagues',
        method: 'get',
      }),
      getLeaguePlaces: new RequestStore<TLeaguePlaces>({
        client: this.client,
        url: '/leagues/${id}',
        method: 'get',
      }),
    };
    makeAutoObservable(this);
  }

  private _leagueDictionary: TLeague[] = [];
  private _leaguePlaces: TLeaguePlaces = [];

  get leagueDictionary() {
    return this._leagueDictionary;
  }

  private setLeagueDictionary(value: typeof this._leagueDictionary) {
    this._leagueDictionary = value;
  }

  get leaguePlaces() {
    return this._leaguePlaces;
  }

  private setLeaguePlaces(value: typeof this._leaguePlaces) {
    this._leaguePlaces = value;
  }

  public async getLeagueDictionary() {
    return await this.request.getLeagueDictionary.request().then(() => {
      const { data } = this.request.getLeagueDictionary.result;

      if (data) {
        this.setLeagueDictionary(data);
        return true;
      }
      return false;
    });
  }

  public async getLeaguePlaces(id: number) {
    return await this.request.getLeaguePlaces.request({ urlProps: { id } }).then(() => {
      const { data } = this.request.getLeaguePlaces.result;

      if (data) {
        this.setLeaguePlaces(data);
        return true;
      }
      return false;
    });
  }
}
