import './ErrorOverlay.css';
import type { AxiosResponse } from 'axios/index';

interface IProps {
  error: AxiosResponse<object>;
}

export const ErrorOverlay = (props: IProps) => {
  const { error } = props;

  return (
    <div className='error-overlay'>
      <img
        src={'/assets/ui_img_construction.png'}
        style={{ width: '80%', margin: '0 auto' }}
        alt='under-construction'
      />
      <span style={{ color: '#FFFFFF', fontFamily: 'Barlow-Regular' }}>
        {`The error has occurred.`}
      </span>
      <span style={{ color: '#FFFFFF', fontFamily: 'Barlow-Regular' }}>{`(${error?.status})`}</span>
    </div>
  );
};
