import './ReferralReward.css';
import { Typography } from 'antd';
import { coinImages, PriceEnum } from '../../../types/shop';

interface IProps {
  icon: string;
  title: string;
  reward: number;
  playCoinReward?: number;
}

export const ReferralReward = (props: IProps) => {
  const { reward, title, icon, playCoinReward } = props;

  return (
    <div className='referral-item'>
      <img className='referral-icon' src={icon} alt='coin-icon' />
      <div className='referral-description-container'>
        <Typography.Text className='referral-title'>{title}</Typography.Text>
        <div className='referral-description'>
          {Boolean(playCoinReward) && (
            <>
              <img
                className='referral-coin-icon'
                src={coinImages[PriceEnum.PLAYCOIN]}
                alt='coin-icon'
              />
              <Typography.Text className='referral-coin-text'>+{playCoinReward}</Typography.Text>
            </>
          )}
          <img className='referral-coin-icon' src={coinImages[PriceEnum.SOFT]} alt='coin-icon' />
          <Typography.Text className='referral-coin-text'>+{reward}</Typography.Text>
          <Typography.Text className='referral-description-text'>
            for you and your friend
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
