/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { CombatUi } from '../CombatUi';
import { Loader } from '../main/Loader';
import { observer } from 'mobx-react';
import { LoadSceneEnum, SceneTypeEnum } from '../../types/scene';
import { useStores } from '../../hooks/useStore';
import { useLocation } from 'react-router';
import { ProjectRoutes } from '../../const/ProjectRoutes';
import { ReactActions, UnityCallbacks } from '../../types/actions';
import { UpgradeUi } from '../UpgradeUi';
import { ModelTypeEnum, ModelTypesActions } from '../../types/upgrade';
import { getCurrentFormula, getFormula } from '../../utils/formulas';
import { toast } from 'react-toastify';

const sceneForRoute = {
  [ProjectRoutes.League]: LoadSceneEnum.Empty,
  [ProjectRoutes.Referral]: LoadSceneEnum.Empty,
  [ProjectRoutes.Shop]: LoadSceneEnum.Empty,
  [ProjectRoutes.Pause]: LoadSceneEnum.Empty,
  [ProjectRoutes.Quests]: LoadSceneEnum.Empty,
  [ProjectRoutes.Combat]: LoadSceneEnum.BattleScene,
  [ProjectRoutes.Upgrade]: LoadSceneEnum.MergeScene,
};

export const UnityContainer = observer(() => {
  const { configS, gameS } = useStores();
  const { pathname } = useLocation();

  const [isPreGameOpen, setIsPreGameOpen] = useState<boolean>(true);
  const [isSceneLoaded, setIsSceneLoaded] = useState<boolean>(false);
  const [isPlayerStep, setIsPlayerStep] = useState<boolean>(true);
  const [isShowCombatButton, setIsShowCombatButton] = useState<boolean>(false);
  const [isBattleInited, setBattleInited] = useState(false);
  const [isGameOver, setIsGameOver] = useState<boolean>(false);
  const [isRevive, setIsRevive] = useState<boolean>(false);
  const [roundReward, setRoundReward] = useState<number | undefined>();
  const [playDeckReward, setPlayDeckReward] = useState<number | undefined>();
  const [amountOfDamage, setAmountOfDamage] = useState<string | undefined>();
  const [softProfit, setSoftProfit] = useState<number>(0);
  const [playDeckProfit, setPlayDeckProfit] = useState<number>(0);

  const [currentRound, setCurrentRound] = useState<number>(1);
  const [enemyHp, setEnemyHp] = useState(
    getFormula(getCurrentFormula(currentRound, configS?.config?.enemyHPFormula), [
      {
        key: 'r',
        value: currentRound,
      },
    ]),
  );
  const [playerHp, setPlayerHp] = useState(
    getFormula(getCurrentFormula(gameS?.gameInfo?.hpGrade, configS?.config?.playerHPFormula), [
      {
        key: 'N',
        value: gameS?.gameInfo?.hpGrade,
      },
    ]),
  );
  const [tapsCount, setTapsCount] = useState<number>(0);
  const unityBuildSalt = process.env.REACT_APP_UNITY_BUILD_SALT ?? '';
  const { unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded } =
    useUnityContext({
      loaderUrl: `./unity_build${unityBuildSalt}/PunchOut.loader.js`,
      dataUrl: `./unity_build${unityBuildSalt}/PunchOut.data`,
      frameworkUrl: `./unity_build${unityBuildSalt}/PunchOut.framework.js`,
      codeUrl: `./unity_build${unityBuildSalt}/PunchOut.wasm`,
      streamingAssetsUrl: `./StreamingAssets${unityBuildSalt}`,
      cacheControl: handleCacheControl,
    });

  const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio);

  useEffect(
    function () {
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      const mediaMatcher = window.matchMedia(`screen and (resolution: ${devicePixelRatio}dppx)`);
      mediaMatcher.addEventListener('change', updateDevicePixelRatio);
      return function () {
        mediaMatcher.removeEventListener('change', updateDevicePixelRatio);
      };
    },
    [devicePixelRatio],
  );

  function handleCacheControl(url: any) {
    if (url.match(/\.bundle/)) {
      return 'must-revalidate';
    }
    return 'no-store';
  }

  useEffect(() => {
    loadScene(sceneForRoute[pathname]);
  }, [pathname]);

  useEffect(() => {
    if (isLoaded) {
      sendMessage(
        'ReactEventsHandler',
        ReactActions.SetAdressablesRemoteURL,
        'https://models.cdn.punchoutcrypto.com/WebGL',
      );
    }
  }, [isLoaded]);

  function handleStartButton() {
    if (isGameOver) {
      /*if (!gameS?.gameInfo?.tutorialPassed) {
        gameS?.completeTutorial();
      }*/

      /*if (gameS?.gameInfo?.tutorialPassed) {
        gameS?.dropTutorial();
      }*/

      loadScene(LoadSceneEnum.Empty);
      setIsGameOver(false);
      return;
    }

    gameS?.startBattle(currentRound).then(() => {
      const { status, errorData } = gameS?.request?.startBattle?.result;

      if (status) {
        sendMessage('ReactEventsHandler', ReactActions.StartBattle, currentRound);
        setIsPreGameOpen(false);
      } else {
        toast.success(errorData?.detail, {
          toastId: 'error-start-battle',
          closeButton: false,
          icon: false,
          progress: undefined,
        });
      }
    });
  }

  const restartBattle = () => {
    sendMessage('ReactEventsHandler', ReactActions.RestartBattle);
  };

  const handleUpdateHP = (isPlayer: any, score: React.SetStateAction<number>) => {
    if (isPlayer) {
      setPlayerHp(score);
    } else {
      setEnemyHp(score);
      if (score <= 0) {
        gameS?.nextRound().then((res) => {
          if (res) {
            continueBattle();
            setRoundReward(res.roundReward);
            setSoftProfit((prev) => {
              return prev + res.roundReward;
            });

            if (res.playDeckReward) {
              setPlayDeckReward(res.playDeckReward);
              setPlayDeckProfit((prev) => {
                return prev + res.playDeckReward;
              });
            }
          }
        });
      }
    }
  };

  const continueBattleAfterRevive = () => {
    setIsRevive(false);
    restartBattle();
  };

  const continueBattle = () => {
    sendMessage('ReactEventsHandler', ReactActions.ContinueBattle);
  };

  const battleData = () => {
    const result = {
      ...gameS?.gameInfo,
      ...configS?.config,
      tapMaxAttackCount: getFormula(configS?.config?.tapMaxAttackCountFormula, [
        {
          key: 'Y',
          value: gameS?.gameInfo?.tapAttackGrade,
        },
      ]),
      tapMaxDefenseCount: getFormula(configS?.config?.tapMaxDefenseCountFormula, [
        {
          key: 'Y',
          value: gameS?.gameInfo?.tapDefenseGrade,
        },
      ]),
    };
    return result;
  };

  const resetStatesForUi = () => {
    setCurrentRound(1);
    setBattleInited(false);
    setIsPreGameOpen(true);
    setIsPlayerStep(true);
    setSoftProfit(0);
    setPlayDeckProfit(0);
    setIsGameOver(false);
    setIsRevive(false);
  };

  const handleSceneLoaded = (index: number) => {
    console.log(index, ' scene index, react side');

    switch (index) {
      case SceneTypeEnum.Empty:
        if (ProjectRoutes.Combat === pathname) {
          resetStatesForUi();
          loadScene(LoadSceneEnum.BattleScene);
        }

        if (ProjectRoutes.Upgrade === pathname) {
          loadScene(LoadSceneEnum.MergeScene);
        }
        break;
      case SceneTypeEnum.BattleScene:
        resetStatesForUi();
        if (!gameS?.gameInfo) {
          gameS?.fetchGameInfo().then(() => {
            sendMessage(
              'ReactEventsHandler',
              ReactActions.SetBattleData,
              JSON.stringify(battleData()),
            );
          });
        } else {
          sendMessage(
            'ReactEventsHandler',
            ReactActions.SetBattleData,
            JSON.stringify(battleData()),
          );
        }
        break;
      case SceneTypeEnum.MergeScene:
        loadModel(
          ModelTypeEnum.WEAPON,
          gameS?.gameInfo?.weaponGrade || 0,
          gameS?.gameInfo?.weaponPurchased || false,
        );
        break;
    }
    setIsSceneLoaded(true);
  };

  const handleBattleInited = () => {
    setIsShowCombatButton(false);
    setBattleInited(true);
  };

  const handleUpdateRound = (round: number) => {
    console.log(round, ' round, react side');
    setRoundReward(undefined);
    setPlayDeckReward(undefined);
    setCurrentRound(round);
  };

  const handleFloatingText = (x: number, y: number, text: string) => {
    const indexOfN = text.indexOf('=');
    const indexOfMinus = text.lastIndexOf('-');
    setAmountOfDamage(indexOfN > 0 ? text.slice(indexOfMinus) : parseFloat(text).toFixed(1).toString());
  };

  const handleAttackStarted = (isPlayerAttack: boolean) => {
    setTapsCount(0);
    setAmountOfDamage(undefined);
    setIsPlayerStep(isPlayerAttack);
    setIsShowCombatButton(true);
  };

  const handleAttackEnded = () => {
    setIsShowCombatButton(false);
  };

  const handleGameOver = () => {
    setIsRevive(true);
  };

  const onGameOver = () => {
    setIsRevive(false);
    gameS.fetchBattleResult(Boolean(playDeckProfit)).then(() => {
      setIsGameOver(true);
    });
  };

  const loadScene = (scene: LoadSceneEnum) => {
    setIsSceneLoaded(false);
    sendMessage('ReactEventsHandler', scene);
  };

  const loadModel = (type: ModelTypeEnum, grade: number, isPurchased: boolean) => {
    sendMessage(
      'ReactEventsHandler',
      ModelTypesActions[type],
      JSON.stringify({
        grade,
        isPurchased,
      }),
    );
  };

  const purchaseItem = () => {
    sendMessage('ReactEventsHandler', ReactActions.NewGradePurchased);
  };

  const setUnityTimeScale = (timescale: 1 | 0) => {
    sendMessage('ReactEventsHandler', ReactActions.SetTimeScale, timescale);
  };

  const handleNewTap = () => {
    setTapsCount((prev) => prev + 1);
  };

  useEffect(() => {
    // @ts-ignore
    addEventListener(UnityCallbacks.OnUpdateHP, handleUpdateHP);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnFloatingText, handleFloatingText);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnSceneLoaded, handleSceneLoaded);
    addEventListener(UnityCallbacks.OnBattleInited, handleBattleInited);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnUpdateRound, handleUpdateRound);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnNewAttackStarted, handleAttackStarted);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnAttackEnded, handleAttackEnded);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnGameOver, handleGameOver);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnNewTap, handleNewTap);

    return () => {
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnUpdateHP, handleUpdateHP);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnFloatingText, handleFloatingText);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnSceneLoaded, handleSceneLoaded);
      removeEventListener(UnityCallbacks.OnBattleInited, handleBattleInited);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnUpdateRound, handleUpdateRound);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnNewAttackStarted, handleAttackStarted);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnAttackEnded, handleAttackEnded);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnGameOver, handleGameOver);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnNewTap, handleNewTap);
    };
  });

  return (
    <>
      {(!isLoaded || !isSceneLoaded) && <Loader />}
      <Unity
        unityProvider={unityProvider}
        devicePixelRatio={devicePixelRatio}
        style={{
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
          position: 'absolute',
          zIndex: 0,
          visibility: isLoaded ? 'visible' : 'hidden',
        }}
      />
      {pathname === ProjectRoutes.Combat && (
        <CombatUi
          softProfit={softProfit}
          playDeckProfit={playDeckProfit}
          reward={roundReward}
          playDeckReward={playDeckReward}
          setRound={setCurrentRound}
          amountOfDamage={amountOfDamage}
          playerHp={playerHp}
          enemyHp={enemyHp}
          round={currentRound}
          isRevive={isRevive}
          isGameOver={isGameOver}
          isPreGameOpen={isPreGameOpen}
          isShowCombatButton={isShowCombatButton}
          isBattleInitialized={isBattleInited}
          isPlayerStep={isPlayerStep}
          tapsCount={tapsCount}
          onStartBattleClick={handleStartButton}
          onSetTimeScale={setUnityTimeScale}
          onContinueBattle={continueBattleAfterRevive}
          onGameOver={onGameOver}
        />
      )}
      {pathname === ProjectRoutes.Upgrade && (
        <UpgradeUi onLoadModel={loadModel} onPurchase={purchaseItem} />
      )}
    </>
  );
});
