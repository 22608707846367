import type { DailyLoginItem } from '../../../types/quests';
import './DailyBonus.css';
import { coinImages } from '../../../types/shop';

interface IProps {
  item: DailyLoginItem;
  dailyDay: number;
  dailyClaimed: boolean;
  hasSub: boolean;
}

export const DailyBonus = (props: IProps) => {
  const { item, dailyDay, dailyClaimed, hasSub } = props;

  const isClaimed = dailyDay > item.day || (dailyDay === item.day && dailyClaimed);
  return (
    <div className='daily-bonus-item'>
      <span className='daily-title'>{`DAY ${item.day}`}</span>
      <div
        className='daily-coin-background'
        style={{
          backgroundImage: `url(${dailyDay === item.day && !dailyClaimed ? './assets/ui_reward_bg_m_act.png' : './assets/ui_reward_bg_m.png'}`,
        }}
      >
        <img
          src={coinImages[item.coinType]}
          alt='coin-icon'
          style={{ opacity: item.day > dailyDay ? 0.4 : 1 }}
        />
        {isClaimed && (
          <img
            style={{ position: 'absolute', width: '24px', right: '-12px' }}
            src={'/assets/ui_button_check.png'}
            alt='check-icon'
          />
        )}
      </div>
      <div className='daily-reward-container'>
        <span className={hasSub ? 'daily-reward' : 'daily-reward-act'}>{`+${item.reward}`}</span>
        <span
          className={!hasSub ? 'daily-reward' : 'daily-reward-act'}
        >{`+${item.subReward}`}</span>
      </div>
    </div>
  );
};
