import { useEffect } from 'react';
import { Typography } from 'antd';
import './Currency.css';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router';
import { ProjectRoutes } from '../../const/ProjectRoutes';
import { useStores } from '../../hooks/useStore';
import { leagueIcons } from '../../types/league';
import { coinImages, PriceEnum } from '../../types/shop';

interface ICoinContainer {
  icon: string;
  text: string;
  onPlusClick?: () => void;
}

const CoinContainer = (props: ICoinContainer) => {
  const { icon, text, onPlusClick } = props;

  return (
    <div
      onClick={onPlusClick}
      className='coin-container'
      style={{ width: !onPlusClick ? '25%' : '25%' }}
    >
      <img src={icon} className='coin-icon' alt='coin-icon' />
      <Typography.Text style={{ marginRight: !onPlusClick ? '20px' : 0 }} className='currency-text'>
        {text}
      </Typography.Text>
      {onPlusClick && (
        <img className='coin-button' src='/assets/ui_topbar_block_plus.png' alt='plus-icon' />
      )}
    </div>
  );
};

interface ICurrencyProps {
  isShop?: boolean;
  onPauseClick?: () => void;
  onShopClick?: (path: string) => void;
  onLeagueClick?: (path: string) => void;
  sticky?: boolean;
}

export const Currency = observer((props: ICurrencyProps) => {
  const { gameS, leagueS } = useStores();
  const { isShop, onPauseClick, sticky, onShopClick, onLeagueClick } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (leagueS?.leagueDictionary?.length === 0 && !leagueS?.request?.getLeagueDictionary?.loader) {
      leagueS?.getLeagueDictionary();
    }
  }, []);

  useEffect(() => {
    if (!gameS?.gameInfo && !gameS?.request.gameInfo.loader) {
      gameS?.fetchGameInfo();
    }
  }, []);

  const onShopButtonClick = () => {
    if (onShopClick) {
      onShopClick(ProjectRoutes.Shop);
    } else {
      if (pathname !== ProjectRoutes.Shop) {
        navigate(ProjectRoutes.Shop);
      }
    }
  };

  const onLeagueButtonClick = () => {
    if (onLeagueClick) {
      onLeagueClick(ProjectRoutes.League);
    } else {
      if (pathname !== ProjectRoutes.League) {
        navigate(ProjectRoutes.League);
      }
    }
  };

  return (
    <div className='currency-container' style={sticky ? { position: 'sticky', top: 0 } : {}}>
      {onPauseClick && <div className='mute-button' onClick={onPauseClick} />}
      <div className='coins-container'>
        {gameS?.gameInfo?.tutorialPassed && !isShop && (
          <div
            onClick={onLeagueButtonClick}
            className='league'
            style={{ backgroundImage: `url(${leagueIcons[gameS?.gameInfo?.leagueId]})` }}
          >
            <span
              style={{
                fontSize: '12px',
                color: 'white',
                fontFamily: 'Barlow-Medium',
                whiteSpace: 'pre-line',
                textShadow: '1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black',
              }}
            >
              {leagueS?.leagueDictionary?.find((x) => x.id === gameS?.gameInfo?.leagueId)?.name}
            </span>
          </div>
        )}
        {isShop && <CoinContainer icon='./assets/ui_icon_tgstar_sm.png' text='0' />}
        <CoinContainer
          icon='./assets/ui_icon_currency_crystal.png'
          text={gameS?.playDeckWallet?.balance?.toString() || '0'}
        />
        <CoinContainer
          icon={coinImages[PriceEnum.SOFT]}
          text={gameS?.gameInfo?.softCoins?.toString() || '0'}
          onPlusClick={onShopButtonClick}
        />
      </div>
    </div>
  );
});
