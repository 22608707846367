import type { TFormulaArray, TFormulaReplace } from '../types/formulas';

export const roundWithDot = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const getFormula = (formula: string | undefined, toReplace: TFormulaReplace[]) => {
  if (!formula) {
    return 0;
  }

  let replacedFormula = formula;

  toReplace.forEach((x) => {
    replacedFormula = replacedFormula.replaceAll(x.key, x?.value ? x.value.toString() : '0');
  });

  return eval(`(${replacedFormula})`);
};

export const getSkillUpgradeCost = (
  level: number,
  cost: number[],
  increase: number[],
  insertInInsert: number,
) => {
  const newLevel = level + 1;
  for (let step = 0; step < newLevel; step++) {
    cost.push(increase[step] + cost[step]);
    increase.push(increase[step] + insertInInsert * (step + 2));
  }

  return cost[newLevel - 1];
};

export const numberFormatter = (num: number) => {
  let formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(num);
};

export const getCurrentFormula = (iterator: number, formulas: TFormulaArray) => {
  if (formulas.length === 0) {
    return undefined;
  }

  const result = formulas
    .slice()
    .sort((a, b) => a.max - b.max)
    .find((x) => iterator < x.max);

  if (result) {
    return result.formula;
  }
  return formulas[formulas.length - 1].formula;
};
