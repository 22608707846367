import './Loader.css';

export const Loader = () => {
  return (
    <div className='loader-screen'>
      <span className='loader-title'>PunchOut</span>
      <img src='/assets/ui_img_rewards.png' alt='loader-image' />
      <span className='loader-description'>Earn coins for every defeated enemy!</span>
      <div
        className='loader'
        style={{ backgroundImage: `url("./assets/ui_icon_preloader_l.png")` }}
      />
    </div>
  );
};
