import './ShopUi.css';
import { useEffect } from 'react';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { observer } from 'mobx-react';
import ReactGA from 'react-ga4';
import { ANALYTIC_SHOP_OPEN } from '../../const/AnalyticValues';
import { useStores } from '../../hooks/useStore';
import { TelegramPaymentTypes } from '../../types/payments';
import type { TShopItem } from '../../types/shop';
import { PriceEnum, ShopItemEnum } from '../../types/shop';
import { Currency } from '../Currency';
import { CoinPackItem, SubPackItem } from '../Shop';

export const ShopUi = observer(() => {
  const { gameS, userS } = useStores();
  const webApp = useWebApp();

  useEffect(() => {
    const isShopRequestSend = localStorage.getItem(ANALYTIC_SHOP_OPEN);

    if (
      !isShopRequestSend &&
      process.env.REACT_APP_ENVIRONMENT === 'production' &&
      process.env.REACT_APP_GTAG
    ) {
      localStorage.setItem(ANALYTIC_SHOP_OPEN, 'sent');
      ReactGA.event(
        {
          category: 'navigation',
          action: 'button_click',
          label: 'Open shop',
        },
        { user: userS?.user?.id },
      );
    }
  }, []);

  useEffect(() => {
    if (gameS?.shop?.length !== 0) {
      gameS.getShopItems();
    }
  }, []);

  useEffect(() => {
    if (gameS?.userSubs?.length !== 0) {
      gameS.getUserSubs();
    }
  }, []);

  const onShopClick = (item: TShopItem) => {
    if (item.coinType !== PriceEnum.STARS && !gameS.request.buyShopItem.loader) {
      gameS.buyShopItem(item);
      return;
    }

    gameS?.getPaymentLink(item.id).then((res) => {
      if (res) {
        webApp.openInvoice(res, (res: unknown) => {
          switch (res) {
            case TelegramPaymentTypes.paid:
              console.log('PAID');
              if (item.itemType.includes('SUB')) {
                gameS.getUserSubs();
              } else {
                gameS?.fetchGameInfo();
              }
              break;
            case TelegramPaymentTypes.failed:
              console.log('FAILED');
              break;
            case TelegramPaymentTypes.cancelled:
              console.log('CANCEL');
              break;
            case TelegramPaymentTypes.pending:
              console.log('PEND');
              break;
          }
        });
      }
    });
  };

  const isBalanceEnough = (item: TShopItem): boolean => {
    switch (item.coinType) {
      case PriceEnum.PLAYCOIN:
        return gameS?.gameInfo?.hardCoins !== undefined && item.price <= gameS?.gameInfo?.hardCoins;
      case PriceEnum.SOFT:
        return gameS?.gameInfo?.softCoins !== undefined && item.price <= gameS?.gameInfo?.softCoins;
      default:
        return true;
    }
  };

  const canBuyCondition = (item: TShopItem) => {
    if (item.itemType === ShopItemEnum.FREE_RESURRECTION_PACK) {
      return gameS?.gameInfo?.freeResurrectionCount === 0 && isBalanceEnough(item);
    }

    if (item.itemType === ShopItemEnum.SOFT_COIN_PACK) {
      return isBalanceEnough(item);
    }

    return (
      !Boolean(gameS?.userSubs?.find((sub) => sub.shopItem.id === item.id)) && isBalanceEnough(item)
    );
  };

  const onTimerExpire = (item: TShopItem) => {
    gameS?.setUserSubs(gameS?.userSubs?.filter((x) => x.shopItem.id !== item.id));
  };

  return (
    <div className='shop-ui-layout'>
      <div className='shop-ui-container'>
        <Currency sticky />
        <div className='shop-container'>
          <span className='coins-text'>Get coins!</span>
          <div className='shop-items'>
            {gameS?.shop
              ?.filter((item) => item.itemType === ShopItemEnum.SOFT_COIN_PACK)
              ?.sort(function (a, b) {
                return a.amount - b.amount;
              })
              .map((item, index) => {
                return (
                  <CoinPackItem
                    canBuy={canBuyCondition(item)}
                    index={index}
                    key={item.id}
                    item={item}
                    onClick={onShopClick}
                  />
                );
              })}
          </div>
          <span className='coins-text'>Boost your abilities!</span>
          {gameS?.shop
            ?.filter((item) => item.itemType !== ShopItemEnum.SOFT_COIN_PACK)
            .map((item) => {
              return (
                <SubPackItem
                  hasResurrection={gameS?.gameInfo?.freeResurrectionCount}
                  hasSub={gameS?.userSubs?.find((sub) => sub.shopItem.id === item.id)?.activeUntil}
                  canBuy={canBuyCondition(item)}
                  key={item.id}
                  item={item}
                  onClick={onShopClick}
                  onExpire={onTimerExpire}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
});
