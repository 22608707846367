import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';
import { useStores } from '../../hooks/useStore';
import { SkillType } from '../../types/skills';
import {
  getCurrentFormula,
  getFormula,
  getSkillUpgradeCost,
  numberFormatter,
} from '../../utils/formulas';
import { Currency } from '../Currency';
import './UpgradeUi.css';
import type { TScoreItem } from '../ScoreItem';
import { ScoreItem } from '../ScoreItem';
// eslint-disable-next-line import/order
import { ModelTypeEnum } from '../../types/upgrade';
// eslint-disable-next-line import/order
import { Typography } from 'antd';
import { Tabs } from '../Tabs';
import { SkillItem, StatItem } from '../Upgrade';

const tabs: string[] = ['weapon', 'armor', 'boost'];

interface IProps {
  onPurchase: () => void;
  onLoadModel: (type: ModelTypeEnum, grade: number, isPurchased: boolean) => void;
}

export const UpgradeUi = observer((props: IProps) => {
  const { onLoadModel } = props;
  const { gameS, configS } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string>(searchParams.get('type') || 'weapon');
  const getPrice = (grade: number | undefined) => {
    if (!grade) {
      return grade;
    }

    return getFormula(getCurrentFormula(grade, configS?.config?.clothUpgradeFormula), [
        {
          key: 'n',
          value: grade + 1,
        },
      ])
  };

  useEffect(() => {
    console.log('----------------------');
    console.log('current hpGrade=', gameS?.gameInfo?.hpGrade);
    console.log('getPrice=', getPrice(gameS?.gameInfo?.hpGrade ? gameS?.gameInfo?.hpGrade : 0));
    console.log('upper hpGrade=', gameS?.gameInfo?.hpGrade +1 );
    console.log('----------------------');
  }, [gameS?.gameInfo]);

  const updateActiveTab = (tab: string) => {
    setActiveTab(tab);
    setSearchParams((searchParams) => {
      searchParams.set('type', tab);
      return searchParams;
    });

    if (tab === 'weapon') {
      onLoadModel(
        ModelTypeEnum.WEAPON,
        gameS?.gameInfo?.weaponGrade || 0,
        gameS?.gameInfo?.weaponPurchased || false,
      );
    }

    if (tab === 'armor') {
      onLoadModel(
        ModelTypeEnum.ARMOR,
        gameS?.gameInfo?.armorGrade || 0,
        gameS?.gameInfo?.armorPurchased || false,
      );
    }
  };

  const scores: TScoreItem[] = useMemo(() => {
    return [
      {
        title: 'HP',
        value: getFormula(
          getCurrentFormula(gameS?.gameInfo?.hpGrade, configS?.config?.playerHPFormula),
          [
            {
              key: 'N',
              value: gameS?.gameInfo?.hpGrade,
            },
          ],
        ),
      },
      {
        title: 'Damage',
        value: getFormula(
          getCurrentFormula(gameS?.gameInfo?.weaponGrade, configS?.config?.playerDamageFormula),
          [
            {
              key: 'n',
              value: gameS?.gameInfo?.weaponGrade || 1,
            },
            {
              key: 'MT',
              value: configS?.config?.tapMaxCount,
            },
          ],
        ),
      },
      {
        title: 'Armor',
        value: getFormula(
          getCurrentFormula(gameS?.gameInfo?.armorGrade, configS?.config?.clothArmorFormula),
          [
            {
              key: 'n',
              value: gameS?.gameInfo?.armorGrade || 1,
            },
            {
              key: 'MT',
              value: configS?.config?.tapMaxCount,
            },
          ],
        ),
      },
      {
        title: 'TAP ATK',
        value: getFormula(configS?.config?.tapMaxAttackCountFormula, [
          {
            key: 'Y',
            value: gameS?.gameInfo?.tapAttackGrade,
          },
        ]),
      },
      {
        title: 'TAP DEF',
        value: getFormula(configS?.config?.tapMaxDefenseCountFormula, [
          {
            key: 'Y',
            value: gameS?.gameInfo?.tapDefenseGrade,
          },
        ]),
      },
    ];
  }, [gameS.gameInfo, configS.config]);

  const onMergeButtonClick = (type: ModelTypeEnum) => {
    gameS
      ?.mergeItems(type)
      .then(() =>
        onLoadModel(
          type,
          (type === ModelTypeEnum.WEAPON
            ? gameS?.gameInfo?.weaponGrade
            : gameS?.gameInfo?.armorGrade) || 0,
          (type === ModelTypeEnum.WEAPON
            ? gameS?.gameInfo?.weaponPurchased
            : gameS?.gameInfo?.armorPurchased) || false,
        ),
      );
  };

  const onBuyButtonClick = (type: ModelTypeEnum) => {
    gameS
      ?.buyModel(type)
      .then(() =>
        onLoadModel(
          type,
          (type === ModelTypeEnum.WEAPON
            ? gameS?.gameInfo?.weaponGrade
            : gameS?.gameInfo?.armorGrade) || 0,
          (type === ModelTypeEnum.WEAPON
            ? gameS?.gameInfo?.weaponPurchased
            : gameS?.gameInfo?.armorPurchased) || false,
        ),
      );
  };

  const renderTabs = () => {
    if (activeTab === 'weapon') {
      const price = getPrice(gameS?.gameInfo?.weaponGrade);
      const haveMoney = Boolean(gameS?.gameInfo?.softCoins && gameS?.gameInfo?.softCoins >= price);
      return (
        <>
          <StatItem
            damage={getFormula(
              getCurrentFormula(gameS?.gameInfo?.weaponGrade, configS?.config?.playerDamageFormula),
              [
                {
                  key: 'n',
                  value: gameS?.gameInfo?.weaponGrade || 1,
                },
                {
                  key: 'MT',
                  value: configS?.config?.tapMaxCount,
                },
              ],
            )}
            grade={gameS?.gameInfo?.weaponGrade || 1}
          />
          <StatItem
            damage={getFormula(
              getCurrentFormula(gameS?.gameInfo?.weaponGrade, configS?.config?.playerDamageFormula),
              [
                {
                  key: 'n',
                  value: gameS?.gameInfo?.weaponGrade ? gameS?.gameInfo?.weaponGrade + 1 : 2,
                },
                {
                  key: 'MT',
                  value: configS?.config?.tapMaxCount,
                },
              ],
            )}
            grade={gameS?.gameInfo?.weaponGrade || 2}
            newGrade
          />
          <button
            className='buy-button'
            style={{
              opacity: gameS?.gameInfo?.weaponPurchased || !haveMoney ? 0.5 : 1,
            }}
            disabled={gameS?.gameInfo?.weaponPurchased || !haveMoney}
            onClick={() => onBuyButtonClick(ModelTypeEnum.WEAPON)}
          >
            <Typography.Text style={{ marginRight: '4px' }}>{`Buy `}</Typography.Text>
            <div className='coin-icon-button' />
            <Typography.Text style={{ marginLeft: '2px' }}>
              {numberFormatter(price)}
            </Typography.Text>
          </button>
        </>
      );
    }

    if (activeTab === 'armor') {
      const price = getPrice(gameS?.gameInfo?.armorGrade);
      const haveMoney = Boolean(gameS?.gameInfo?.softCoins && gameS?.gameInfo?.softCoins >= price);

      return (
        <>
          <StatItem
            damage={getFormula(
              getCurrentFormula(gameS?.gameInfo?.armorGrade, configS?.config?.clothArmorFormula),
              [
                {
                  key: 'n',
                  value: gameS?.gameInfo?.armorGrade || 1,
                },
                {
                  key: 'MT',
                  value: configS?.config?.tapMaxCount,
                },
              ],
            )}
            grade={gameS?.gameInfo?.armorGrade || 1}
            isArmor
          />
          <StatItem
            damage={getFormula(
              getCurrentFormula(gameS?.gameInfo?.armorGrade, configS?.config?.clothArmorFormula),
              [
                {
                  key: 'n',
                  value: gameS?.gameInfo?.armorGrade ? gameS?.gameInfo?.armorGrade + 1 : 2,
                },
                {
                  key: 'MT',
                  value: configS?.config?.tapMaxCount,
                },
              ],
            )}
            grade={gameS?.gameInfo?.armorGrade || 2}
            isArmor
            newGrade
          />
          <button
            className='buy-button'
            style={{
              opacity: gameS?.gameInfo?.armorPurchased || !haveMoney ? 0.5 : 1,
            }}
            disabled={gameS?.gameInfo?.armorPurchased || !haveMoney}
            onClick={() => onBuyButtonClick(ModelTypeEnum.ARMOR)}
          >
            <Typography.Text style={{ marginRight: '4px' }}>{`Buy `}</Typography.Text>
            <div className='coin-icon-button' />
            <Typography.Text style={{ marginLeft: '2px' }}>
              {numberFormatter(price)}
            </Typography.Text>
          </button>
        </>
      );
    }

    return <></>;
  };

  return (
    <div
      className='upgrade-overlay'
      style={{ backgroundColor: activeTab !== 'boost' ? 'transparent' : '#111138' }}
    >
      <div className='upgrade-header'>
        <Currency sticky />
        <div className='score-container'>
          {scores.map((score, index) => {
            return <ScoreItem key={index} {...score} />;
          })}
        </div>
        <div className='score-container' style={{ marginTop: '12px', justifyContent: 'center' }}>
          <ScoreItem
            {...{
              title: 'BATTLE SCORE',
              value: gameS?.gameInfo?.battlescore,
              battleScore: true,
            }}
          />
        </div>
        {activeTab === 'boost' && (
          <div className='skills-container'>
            <SkillItem
              maxGrade={10}
              grade={gameS?.gameInfo?.tapAttackGrade || 0}
              title='Attack Tap Power'
              img={'./assets/ui_icon_tapatk.png'}
              price={getSkillUpgradeCost(
                gameS?.gameInfo?.tapAttackGrade || 0,
                [configS?.config.skillsUpgradeCost],
                [configS?.config?.skillsUpgradeIncrease],
                configS?.config?.skillsUpgradeInsert,
              )}
              money={gameS?.gameInfo?.softCoins}
              onClick={() => gameS?.upgradeSkill(SkillType.AttackSkill)}
            />
            <SkillItem
              maxGrade={10}
              grade={gameS?.gameInfo?.tapDefenseGrade || 0}
              title='Defence Tap Power'
              img={'./assets/ui_icon_tapdef.png'}
              price={getSkillUpgradeCost(
                gameS?.gameInfo?.tapDefenseGrade || 0,
                [configS?.config.skillsUpgradeCost],
                [configS?.config?.skillsUpgradeIncrease],
                configS?.config?.skillsUpgradeInsert,
              )}
              money={gameS?.gameInfo?.softCoins}
              onClick={() => gameS?.upgradeSkill(SkillType.DefenceSkill)}
            />
            <SkillItem
              maxGrade={400}
              grade={gameS?.gameInfo?.hpGrade || 0}
              title='HP Boost'
              img={'./assets/ui_icon_boost_hp.png'}
              price={getPrice(gameS?.gameInfo?.hpGrade ? gameS?.gameInfo?.hpGrade : 0)}
              money={gameS?.gameInfo?.softCoins}
              onClick={() => gameS?.upgradeSkill(SkillType.HpSkill)}
            />
          </div>
        )}
      </div>
      <div className='upgrade-merge-container'>
        {activeTab !== 'boost' && (
          <div className='upgrade-merge'>
            {activeTab === 'armor' && (
              <button
                className='merge-button'
                style={{
                  backgroundImage: gameS?.gameInfo?.armorPurchased
                    ? `url(./assets/ui_button_merge_act.png)`
                    : `url(./assets/ui_button_merge.png)`,
                }}
                disabled={!gameS?.gameInfo?.armorPurchased}
                onClick={() => onMergeButtonClick(ModelTypeEnum.ARMOR)}
              />
            )}
            {activeTab === 'weapon' && (
              <button
                className='merge-button'
                style={{
                  backgroundImage: gameS?.gameInfo?.weaponPurchased
                    ? `url(./assets/ui_button_merge_act.png)`
                    : `url(./assets/ui_button_merge.png)`,
                }}
                disabled={!gameS?.gameInfo?.weaponPurchased}
                onClick={() => onMergeButtonClick(ModelTypeEnum.WEAPON)}
              />
            )}
            <div className='stats'>{renderTabs()}</div>
          </div>
        )}
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          updateActiveTab={updateActiveTab}
          imageName='ui_merge_tabs_block_0'
        />
      </div>
    </div>
  );
});
