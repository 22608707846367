import type { AxiosInstance } from 'axios';
import { makeAutoObservable } from 'mobx';
import type { ReferralUser, User } from '../models/user';
import { RequestStore } from './RequestStore';

type Props = {
  axiosInstance: AxiosInstance;
};

const transformUser = (initUserData?: User): User => {
  return { id: 0, score_per_click: 1, score: 0, speed: 1, distance: 0, ...initUserData };
};

export class UserStore {
  request;
  private readonly client: AxiosInstance;

  public constructor(props: Props) {
    this.client = props.axiosInstance;
    this.request = {
      getMe: new RequestStore<User>({
        client: this.client,
        url: '/auth/get_me',
        method: 'get',
      }),
      getReferrals: new RequestStore<ReferralUser[]>({
        client: this.client,
        url: '/auth/referrals',
        method: 'get',
      }),
    };
    makeAutoObservable(this);
  }

  private _user: User = transformUser();
  private _playDeck = {};

  public get user(): typeof this._user {
    return this._user;
  }

  private _referrals: ReferralUser[] = [];

  public get referrals(): typeof this._referrals {
    return this._referrals;
  }

  public async fetchMe() {
    await this.request.getMe.request().then(() => {
      const { data, status } = this.request.getMe.result;
      if (!status || !data) {
        this.setUser(transformUser());
      } else {
        this.setUser(transformUser(data));
      }
      return true;
    });
  }

  public async fetchReferrals() {
    await this.request.getReferrals.request().then(() => {
      const { data, status } = this.request.getReferrals.result;
      if (!status || !data) {
        this.setReferrals([]);
      } else {
        this.setReferrals(data);
      }
      return true;
    });
    return true;
  }

  private setUser(value?: typeof this._user) {
    this._user = transformUser(value);
  }

  private setReferrals(value?: typeof this._referrals) {
    this._referrals = value ? value : [];
  }
}
