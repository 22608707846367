import type { TShopItem } from '../../../types/shop';
import { coinImages } from '../../../types/shop';
import './CoinPackItem.css';

interface IProps {
  item: TShopItem;
  onClick: (item: TShopItem) => void;
  index: number;
  canBuy: boolean;
}

const images: string[] = [
  '/assets/ui_store_softcurrency_01.png',
  '/assets/ui_store_softcurrency_02.png',
  '/assets/ui_store_softcurrency_03.png',
];

export const CoinPackItem = (props: IProps) => {
  const { item, onClick, index, canBuy } = props;

  return (
    <div className='shop-item'>
      <span className='shop-title'>{item.name}</span>
      <div>
        <img className='shop-image' src={images[index]} alt='' />
        <span className='amount-text'>x{item.amount}</span>
        <button
          style={{ opacity: canBuy ? 1 : 0.5 }}
          disabled={!canBuy}
          className='shop-buy-button'
          onClick={() => onClick(item)}
        >
          <img src={coinImages[item.coinType]} alt='coin-icon' />
          <span className='shop-buy-button-text'>{item.price}</span>
        </button>
      </div>
    </div>
  );
};
