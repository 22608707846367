import './DailyTask.css';
import { Typography } from 'antd';
import { useNavigate } from 'react-router';
import type { DailyQuest } from '../../../types/quests';
import { QuestRoute } from '../../../types/quests';
import { coinImages } from '../../../types/shop';

interface IProps extends DailyQuest {
  onComplete: (id: number) => void;
}

export const DailyTask = (props: IProps) => {
  const {
    id,
    completed,
    dailyQuest: { amount, questObject, reward, description, condition, coinType },
    progress,
    received,
    onComplete,
  } = props;

  const navigate = useNavigate();

  const onGoClick = () => {
    if (completed && !received) {
      onComplete(id);
    } else {
      navigate(QuestRoute[questObject]);
    }
  };

  const taskProgress = () => {
    if (progress > amount) {
      return amount;
    }

    return progress;
  };

  return (
    <div className='daily-task'>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '8px' }}>
        <div className='daily-reward-background'>
          <img src={coinImages[coinType]} style={{ width: '75%' }} alt='coin' />
        </div>
        <Typography.Text className='daily-task-description' style={{ fontSize: '12px' }}>
          {reward}
        </Typography.Text>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px' }}>
        <Typography.Text className='daily-task-title'>{condition}</Typography.Text>
        {description && (
          <Typography.Text className='daily-task-description'>{description}</Typography.Text>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className='progress-bar'>
            <div
              className='progress-bar-active'
              style={{ width: `${(taskProgress() * 100) / amount}%` }}
            />
            <Typography.Text className='progress-text'>{`${taskProgress()}/${amount}`}</Typography.Text>
          </div>
          <button
            className='get-daily-reward-button'
            style={{
              backgroundImage: completed
                ? `url(./assets/ui_button_sm_act.png)`
                : `url(./assets/ui_button_sm.png)`,
            }}
            onClick={onGoClick}
          >
            <Typography.Text className='get-reward-text'>
              {completed ? 'CLAIM!' : 'GO!'}
            </Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};
