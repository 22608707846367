import { useEffect, useState } from 'react';

const useCountdownTimer = (fromDate: Date, timeOffset?: number) => {
  let countDownDate = new Date(fromDate).getTime() + 1000;

  if (timeOffset) {
    countDownDate = countDownDate + timeOffset * 60 * 1000;
  }

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    if (countDown < 1000) {
      return;
    }

    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate, countDown]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useCountdownTimer };
