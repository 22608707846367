import './AutoFightModal.css';
import { coinImages, PriceEnum } from '../../../types/shop';

interface IProps {
  reward: number;
  onClaimRewardClick: () => void;
}

export const AutoFightModal = (props: IProps) => {
  const { onClaimRewardClick, reward } = props;

  return (
    <div className='auto-fight-modal'>
      <div className='auto-fight-container'>
        <span className='auto-fight-title'>You have earned by auto fight</span>
        <span className='auto-fight-reward-count'>
          <img
            src={coinImages[PriceEnum.SOFT]}
            className='auto-fight-reward-icon'
            alt='currecy-icon'
          />
          {reward}
        </span>
        <button className='auto-fight-button' onClick={onClaimRewardClick}>
          <span>CLAIM REWARDS</span>
        </button>
      </div>
    </div>
  );
};
