import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { Typography } from 'antd';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { TelegramPaymentTypes } from '../../../types/payments';
import { getFormula } from '../../../utils/formulas';
import '../CombatUi.css';

interface IProps {
  onContinueBattle: () => void;
  onGameOver: () => void;
}

export const ReviveUi = observer((props: IProps) => {
  const { configS, gameS } = useStores();
  const { onContinueBattle, onGameOver } = props;
  const webApp = useWebApp();

  const getRevivePrice = () => {
    if (
      (gameS?.gameInfo?.freeResurrectionCount &&
        gameS?.gameInfo?.freeResurrectionCount > 0 &&
        !gameS?.battleInfo?.isFreeResurrectionUsed) ||
      gameS?.gameInfo?.dailyResurrectionCount !== 0
    ) {
      return 'Revive';
    }

    const k = configS?.config?.battleScoreCoefficient?.find((x) => {
      return gameS?.gameInfo?.battlescore! <= x.max && gameS?.gameInfo?.battlescore! >= x.min;
    });

    return (
      <>
        <img
          style={{ width: '16px', margin: '0 4px -2px 0' }}
          src='/assets/ui_icon_tgstar_sm.png'
          alt='soft-coin'
        />
        {getFormula(configS?.config?.payResurrectionFormula, [
          {
            key: 'n',
            value: gameS?.battleInfo?.resurrectionCount! + 1,
          },
          {
            key: 'k',
            value: k ? k.k : 3,
          },
        ])}
      </>
    );
  };

  const onReviveClick = () => {
    if (
      (!gameS?.battleInfo?.isFreeResurrectionUsed &&
        gameS?.gameInfo?.freeResurrectionCount &&
        gameS?.gameInfo?.freeResurrectionCount > 0) ||
      gameS?.gameInfo?.dailyResurrectionCount !== 0
    ) {
      gameS?.freeRevive().then((status) => {
        if (status) {
          onContinueBattle();
        } else {
          onGameOver();
        }
      });
      return;
    }

    gameS?.buyResurrection().then((data) => {
      if (data) {
        webApp.openInvoice(data.link, (res: unknown) => {
          switch (res) {
            case TelegramPaymentTypes.paid:
              console.log('PAID');
              gameS?.paidRevive(data.id).then((data) => {
                if (data) {
                  onContinueBattle();
                }
              });
              break;
            case TelegramPaymentTypes.failed:
              console.log('FAILED');
              break;
            case TelegramPaymentTypes.cancelled:
              console.log('CANCEL');
              break;
            case TelegramPaymentTypes.pending:
              console.log('PEND');
              break;
          }
        });
      }
    });
  };

  return (
    <div className='game-over-overlay'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <span
          className='resurrection-description'
          style={{
            color: '#FFFFFF',
            fontSize: '32px',
            fontFamily: 'Kraft',
            letterSpacing: '2px',
          }}
        >
          DEFEAT!
        </span>
        <img src='/assets/ui_store_revive.png' alt='revive-icon' style={{ width: '240px' }} />
        <span className='resurrection-description'>Revive and continue?</span>
        <button className='resurrection-button' onClick={onReviveClick}>
          <span>{getRevivePrice()}</span>
        </button>
        <Typography.Text
          className='game-over-button-text'
          style={{ fontSize: '12px' }}
          onClick={onGameOver}
        >
          I give up
        </Typography.Text>
      </div>
    </div>
  );
});
