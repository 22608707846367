import { Typography } from 'antd';
import './StatItem.css';
import { numberFormatter } from '../../../utils/formulas';

interface IItemStat {
  grade: number;
  damage: number;
  isArmor?: boolean;
  newGrade?: boolean;
}

export const StatItem = (props: IItemStat) => {
  const { damage, newGrade, grade, isArmor } = props;

  return (
    <div className='item-stat'>
      <div className='item-text-container'>
        <Typography.Text className='item-title'>{newGrade ? 'NEXT LVL' : 'LVL'}</Typography.Text>
        <Typography.Text style={{ color: '#F6BE30' }}>
          {newGrade ? grade + 1 : grade}
        </Typography.Text>
      </div>
      <div className='item-text-container'>
        <Typography.Text className='item-title'>{isArmor ? 'DEF' : 'DMG'}</Typography.Text>
        <Typography.Text style={{ color: '#19C8FA' }}>{numberFormatter(damage)}</Typography.Text>
      </div>
    </div>
  );
};
