import type { AxiosResponse } from 'axios';
import axios from 'axios';
import dayjs from 'dayjs';
import qs from 'qs';
import type TokenStore from '../stores/TokenStore';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/;

export const axiosInstance = ({ tokenStore }: { tokenStore: TokenStore }) => {
  const $axios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  $axios.defaults.headers.post['Content-Type'] = 'application/json';
  $axios.defaults.headers.put['Content-Type'] = 'application/json';
  $axios.defaults.headers.patch['Content-Type'] = 'application/json';

  $axios.interceptors.request.use(
    (config) => {
      const token = tokenStore.token;
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      if (config.url?.includes('undefined')) {
        return config;
      }
      return config;
    },
    (err) => {
      console.error(err);
    },
  );

  $axios.defaults.paramsSerializer = {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' });
    },
  };

  const transformResponseData = (response: AxiosResponse) => {
    // Check if the response data is in JSON format
    if (response.headers['content-type'] === 'application/json') {
      // Recursively transform all dates in the response data
      response.data = transformDates(response.data);
    }
    return response;
  };

  const transformDates = (data: AxiosResponse['data']): unknown => {
    if (!data) {
      return data;
    }
    // If the data is a string and matches the expected date format, convert it to a dayjs object
    if (typeof data === 'string' && DATE_REGEX.test(data) && dayjs(data, DATE_FORMAT).isValid()) {
      return dayjs(data, DATE_FORMAT);
    }
    // If the data is an object or array, recursively transform any nested dates
    else if (typeof data === 'object' && !Array.isArray(data)) {
      // Recursively transform any nested objects
      return Object.keys(data).reduce((object: Record<string, unknown>, key) => {
        object[key] = transformDates(data[key]);
        return object;
      }, {});
    } else if (Array.isArray(data)) {
      // Recursively transform any nested arrays
      return data.map((value) => transformDates(value));
    } else {
      // Return non-date values unchanged
      return data;
    }
  };

  $axios.interceptors.response.use(transformResponseData);
  return $axios;
};
