import './ComputerOverlay.css';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';

export const ComputerOverlay = () => {
  const WebApp = useWebApp();

  return (
    <div className='computer-overlay'>
      <span>Play on your mobile</span>
      <img style={{ width: '120px' }} src='./assets/ui_img_playmobile.png' />
      <span style={{ fontSize: '14px' }}>{`platform: ${WebApp?.platform}`}</span>
    </div>
  );
};
