import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { Loader } from '../../components/main/Loader';
import { ProjectRoutes } from '../../const/ProjectRoutes';

const PausePage = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(ProjectRoutes.Combat);
  }, []);

  return <Loader />;
});

export default PausePage;
