import type { AxiosInstance } from 'axios';
import { axiosInstance } from '../utils/Axios';
import { ConfigStore } from './ConfigStore';
import { DailyStore } from './DailyStore';
import { GameStore } from './GameStore';
import { LeagueStore } from './LeagueStore';
import TokenStore from './TokenStore';
import { UserStore } from './UserStore';

class RootStore {
  tokenS: TokenStore;
  $axios: AxiosInstance;
  userS: UserStore;
  gameS: GameStore;
  configS: ConfigStore;
  dailieS: DailyStore;
  leagueS: LeagueStore;

  constructor({ webAppInitData }: { webAppInitData?: string }) {
    this.tokenS = new TokenStore({ webAppInitData });
    this.$axios = axiosInstance({ tokenStore: this.tokenS });
    this.configS = new ConfigStore({ axiosInstance: this.$axios });
    this.userS = new UserStore({
      axiosInstance: this.$axios,
    });
    this.gameS = new GameStore({ axiosInstance: this.$axios });
    this.leagueS = new LeagueStore({ axiosInstance: this.$axios });
    this.dailieS = new DailyStore({ axiosInstance: this.$axios, gameS: this.gameS });
  }
}

export { RootStore };
